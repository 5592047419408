import ApiService from './api.service'
const InspectionBookOfContractorService = {
  GetList(data) {
    return ApiService.post(`/InspectionBookOfContractor/GetList`, data);
  },
  Get(id) {
    return ApiService.get(`/InspectionBookOfContractor/Get/${id}`);
  },
  GetByRequestId(id) {
    return ApiService.get(`/InspectionBookOfContractor/GetByRequestId/${id}`);
  },
  // Create(data){
  //   return ApiService.post(`/InspectionBookOfContractor/Create`,data)
  // },
  // Update(data){
  //   return ApiService.post(`/InspectionBookOfContractor/Update`, data)
  // },
  Update(data) {
    if (data.id == 0) {
      return ApiService.post(`/InspectionBookOfContractor/Create`, data);
    } else {
      return ApiService.post(`/InspectionBookOfContractor/Update`, data);
    }
  },
  Send(data) {
    return ApiService.post(`/InspectionBookOfContractor/Send`, data);
  },
  GetSecurityInfo() {
    return ApiService.print(`/InspectionBookOfContractor/GetSecurityInfo`);
  },
};
export default InspectionBookOfContractorService